import './style/main.scss';

import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';

import 'script-loader!./theproject/plugins/jquery.min';
import 'script-loader!./theproject/bootstrap/js/bootstrap.min';
import 'script-loader!./theproject/plugins/modernizr';
import 'script-loader!./theproject/plugins/rs-plugin/js/jquery.themepunch.tools.min';
import 'script-loader!./theproject/plugins/rs-plugin/js/jquery.themepunch.revolution.min';
import 'script-loader!./theproject/plugins/isotope/isotope.pkgd.min';
import 'script-loader!./theproject/plugins/magnific-popup/jquery.magnific-popup.min';
import 'script-loader!./theproject/plugins/waypoints/jquery.waypoints.min';
import 'script-loader!./theproject/plugins/jquery.countTo';
import 'script-loader!./theproject/plugins/jquery.parallax-1.1.3';
import 'script-loader!./theproject/plugins/jquery.validate';
import 'script-loader!./theproject/plugins/morphext/morphext.min';
import 'script-loader!./theproject/plugins/vide/jquery.vide';
import 'script-loader!./theproject/plugins/owl-carousel/owl.carousel';
import 'script-loader!./theproject/plugins/jquery.browser';
import 'script-loader!./theproject/plugins/SmoothScroll';
import 'script-loader!./theproject/js/template';
import 'script-loader!./theproject/js/custom';

// import PriceList from './components/PriceList.jsx';
// import OrderForm from './components/OrderForm.jsx';
// import HelloWorld from './components/HelloWorld.jsx';

const COMPONENTS = {
  PriceList: () =>
    import(/* webpackChunkName: "reactComponents" */ './components/PriceList.jsx'),
  OrderForm: () =>
    import(/* webpackChunkName: "reactComponents" */ './components/OrderForm.jsx'),
};
// const COMPONENTS = {
//   PriceList,
//   OrderForm,
// };

Array.from(document.querySelectorAll('[data-component]')).forEach(
  async (el: HTMLElement) => {
    const { component, props } = el.dataset;
    console.log(`Trying to load ${component}...`);
    const Component = (await COMPONENTS[component]()).default;
    // const Component = COMPONENTS[component];
    const decodedProps = props ? JSON.parse(props) : {};
    ReactDOM.render(React.createElement(Component, decodedProps), el);
    ReactDOM.render(
      React.createElement(Component, { client: true, ...decodedProps }),
      el
    );
  }
);
